import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State, User } from '@wam/shared';
import { authenticationSelectors } from '@wam/authentication';
import { map, tap } from 'rxjs/operators';
import { isUpstartParent } from '@app/authentication/user.data';
import { trackPageView } from '@snowplow/browser-tracker';

@Injectable({
  providedIn: 'root',
})
export class SnowplowService {
  private isUpstart$ = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    map((user: User) => isUpstartParent(user)),
  );
  private trackSnowplowPageView$ = this.isUpstart$.pipe(
    tap((isUpstart) => {
      if (isUpstart) {
        trackPageView();
      }
    }),
  );
  constructor(protected store: Store<State>) {}

  trackPageView() {
    this.trackSnowplowPageView$.subscribe();
  }
}
